import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTotalPaymentsByStatusDashboard(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/dashboards/total-payments-by-status', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTotalMonthlyFeesChart(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/dashboards/total-monthly-fees', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/dashboard')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
