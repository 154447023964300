<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
        order="5"
        order-md="3"
      >
        <total-payments-by-status></total-payments-by-status>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <total-monthly-commission-fees></total-monthly-commission-fees>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import useDashboardBalance from '@/views/dashboards/general/useDashboardBalance'

// icons

// demos
import TotalPaymentsByStatus from './TotalPaymentsByStatus.vue'
import TotalMonthlyCommissionFees from './TotalMonthlyCommissionFees.vue'
import store from '@/store'
import storeModule from '../storeModule'

export default {
  components: {
    TotalPaymentsByStatus,
    TotalMonthlyCommissionFees,
  },
  setup() {
    const STORE_MODULE_NAME = 'processing-dashboards'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
  },
}
</script>
